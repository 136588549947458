import React, { useEffect, useState } from "react";
import { Tabs, DatePicker, message } from "antd";
import dayjs, { Dayjs } from "dayjs";
import API from "../api";
import style from "../style/tableCol.module.scss";
import BookingTable from "./bookingTable";
import BookingDetailsDrawer from "./BookingDetailsDrawer";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

function Booking() {
  const scheduleTabs = ["Pickup", "Dropoff", "Completed"];
  const [currentKey, setCurrentKey] = useState(scheduleTabs[0]);
  const [bookingData, setBookingData] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [filterDates, setFilterDates] = useState<[Dayjs, Dayjs]>([
    dayjs().startOf("day"), // Default: Today at start of the day
    dayjs().add(1, "day").endOf("day"), // Default: Tomorrow at end of the day
  ]);

  const fetchBookingData = async () => {
    try {
      // Mapping booking and ride status based on tabs
      const statusMapping: Record<
        string,
        { bookingStatus: string; rideStatus: string }
      > = {
        Pickup: { bookingStatus: "BOOKED", rideStatus: "NOT_STARTED" },
        Dropoff: { bookingStatus: "BOOKED", rideStatus: "ONGOING" },
        Completed: { bookingStatus: "BOOKED", rideStatus: "COMPLETED" },
      };

      const { bookingStatus, rideStatus } = statusMapping[currentKey];

      // Constructing the request payload
      const requestPayload: Record<string, any> = {
        bookingStatus,
        rideStatus,
      };

      // Adding date range for the current tab
      if (filterDates) {
        const [start, end] = filterDates;

        if (currentKey === "Pickup") {
          requestPayload.pickupFrom = start.toISOString();
          requestPayload.pickupTo = end.toISOString();
        } else if (currentKey === "Dropoff" || currentKey === "Completed") {
          requestPayload.dropoffFrom = start.toISOString();
          requestPayload.dropoffTo = end.toISOString();
        }
      }

      console.log("API Request Payload:", requestPayload); // Debugging the payload

      // Making the API call
      const response = await API.admin.GetBookingData(requestPayload);

      if (!API.validators.checkSuccessCode(response)) {
        message.error("Technical error while fetching booking data");
        return;
      }

      setBookingData(response.data?.data || []);
    } catch (error) {
      console.error("Error fetching booking data:", error);
      message.error("Error fetching booking data");
    }
  };

  const handleDateRangeChange = (
    dates: [Dayjs | null, Dayjs | null] | null
  ) => {
    if (dates) {
      const [start, end] = dates;

      if (start && end && end.isBefore(start)) {
        message.error("End date cannot be earlier than start date.");
        return;
      }

      setFilterDates([
        start?.startOf("day") || dayjs().startOf("day"),
        end?.endOf("day") || dayjs().add(1, "day").endOf("day"),
      ]);
    }
  };

  const handleTabChange = (key: string) => {
    setCurrentKey(key);
    // Do NOT reset dates when switching tabs; retain the current `filterDates`.
  };

  useEffect(() => {
    fetchBookingData();
  }, [filterDates, currentKey]);

  return (
    <div>
      {showDrawer && (
        <BookingDetailsDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          selectedData={selectedData}
          isSchedulePage
        />
      )}
      <div className={style["tabs-and-daterange"]}>
        {/* Tabs */}
        <Tabs
          type="card"
          size="middle"
          defaultActiveKey={currentKey}
          onChange={handleTabChange}
        >
          {scheduleTabs.map((tab) => (
            <TabPane tab={tab} key={tab} />
          ))}
        </Tabs>

        {/* Date Range Picker */}
        <RangePicker
          onChange={handleDateRangeChange}
          value={filterDates}
          getPopupContainer={(trigger: any) => trigger.parentNode}
        />
      </div>

      {/* Booking Table */}
      <BookingTable
        data={bookingData}
        setShowDrawer={setShowDrawer}
        setSelectedData={setSelectedData}
        isPickup={currentKey === "Pickup"}
        isDropOff={currentKey === "Dropoff"}
      />
    </div>
  );
}

export default Booking;
