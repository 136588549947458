export const changeTextColor = (status: string) => {
  if (status === "BOOKED" || status === "COMPLETED" || status === "BOOKING") {
    return "green";
  } else if (status === "CANCELLED") {
    return "red";
  } else if (status === "NOT_STARTED") {
    return "rgb(216 128 44 / 74%)";
  } else if (status === "ONGOING") {
    return "orange";
  } else if (status === "PAID") {
    return "#81b622";
  }

  return "#eab078bd";
};
