import React from "react";
import "./App.css";
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import AdminLayout from "./Admin/admin-layout";
import AdminDashboard from "./Admin/admin-dashboard";
import City from "./Admin/city";
import { storage, SUB_DOMAIN_TYPE } from "./utils/storage";
import Partners from "./Admin/partners";
import Bike from "./Admin/bikes";
import Booking from "./Admin/booking";
import Accessories from "./Admin/accessories";
import PartnerDashboard from "./partner/partner-dashboard";
import PartnerLayout from "./partner/partner-layout";
import PartnerBikes from "./partner/bikes";
import PartnerAccessories from "./partner/accessories";
import LoginForm from "./components/LoginForm";
import PartnerBikeBlocks from "./partner/blocks";
import Schedule from "./Admin/Schedule";

function App() {
  const router = createBrowserRouter([
    {
      id: "root",
      path: "/",
      loader: () => {
        //This handles subdomain based routing
        const hostname = window.location.hostname;
        let subdomainType: SUB_DOMAIN_TYPE = SUB_DOMAIN_TYPE.PARTNER;
        const domainParts = hostname.split(".");
        if (domainParts.length > 1) {
          const subdomain = domainParts[0];
          if (subdomain.indexOf("partner") >= 0) {
            subdomainType = SUB_DOMAIN_TYPE.PARTNER;
          } else if (subdomain.indexOf("admin") >= 0) {
            subdomainType = SUB_DOMAIN_TYPE.ADMIN;
          }
        }
        return redirect(`/${subdomainType}${window.location.search}`);
      },
    },
    {
      id: "admin",
      path: "/admin",
      children: [
        {
          index: true,
          element: <LoginForm isAdmin />,
          loader: () => {
            // CHECK FOR LOGIN AND THROW THE USER TO DASHBOARD IF ALREADY LOGGED IN
            const { hasAccess, userType } = storage.checkAdminDashboardAccess();
            if (userType === SUB_DOMAIN_TYPE.PARTNER)
              return redirect("/partner");
            if (hasAccess) {
              return redirect("/admin/dashboard");
            }
            return null;
          },
        },
        {
          id: "admin-dashboard",
          path: "dashboard",
          loader: () => {
            //CHECK FOR LOGIN AND THROW THE USER TO DASHBOARD IF ALREADY LOGGED IN
            const { hasAccess, userType } = storage.checkAdminDashboardAccess();
            if (userType === SUB_DOMAIN_TYPE.PARTNER)
              return redirect("/partner");
            if (!hasAccess) return redirect("/admin");
            return null;
          },
          element: <AdminLayout />,
          children: [
            {
              index: true,
              path: "home",
              element: <AdminDashboard />,
            },
            {
              path: "city",
              element: <City />,
            },
            {
              path: "partners",
              element: <Partners />,
            },
            {
              path: "bikes",
              element: <Bike />,
            },
            {
              path: "accessories",
              element: <Accessories />,
            },
            {
              path: "booking",
              element: <Booking />,
            },
            {
              path: "schedule",
              element: <Schedule />,
            },
          ],
        },
      ],
    },
    {
      id: "partner",
      path: "/partner",
      children: [
        {
          index: true,
          element: <LoginForm isPatner />,
          loader: () => {
            // CHECK FOR LOGIN AND THROW THE USER TO DASHBOARD IF ALREADY LOGGED IN
            const { hasAccess, userType } =
              storage.checkPartnerDashboardAccess();
            if (userType === SUB_DOMAIN_TYPE.ADMIN) return redirect("/admin");
            if (hasAccess) return redirect("/partner/dashboard");
            return null;
          },
        },
        {
          id: "partner-dashboard",
          path: "dashboard",
          loader: () => {
            //CHECK FOR LOGIN AND THROW THE USER TO DASHBOARD IF ALREADY LOGGED IN
            const { hasAccess, userType } =
              storage.checkPartnerDashboardAccess();
            if (userType === SUB_DOMAIN_TYPE.ADMIN) return redirect("/admin");
            if (!hasAccess) return redirect("/partner");
            return null;
          },
          element: <PartnerLayout />,
          children: [
            {
              index: true,
              element: <PartnerDashboard />,
            },
            {
              path: "bikes",
              element: <PartnerBikes />,
            },
            {
              path: "accessories",
              element: <PartnerAccessories />,
            },
            {
              path: "blocks",
              element: <PartnerBikeBlocks />,
            },
            {
              path: "booking",
              element: <Booking isPartner />,
            },
            {
              path: "schedule",
              element: <Schedule />,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
  );
}

export default App;
