import React, { useState } from "react";
import { ReactComponent as MotorcycleIcon } from "../assests/nav/motorcycle.svg";
import { ReactComponent as Blocks } from "../assests/nav/block.svg";
import { ReactComponent as HomeIcon } from "../assests/nav/home.svg";
import { ReactComponent as BookingIcon } from "../assests/nav/bookingIcon.svg";
import { ReactComponent as ScheduleIcon } from "../assests/nav/schedule.svg";
import { Layout, Menu, theme } from "antd";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import style from "../style/layouts.module.scss";
import TopHeader from "../components/TopHeader";

enum ROUTE {
  HOME = "",
  BIKES = "bikes",
  ACCESSORIES = "accessories",
  PARTNER_BIKES_BLOCKS = "blocks",
  BOOKING = "booking",
  SCHEDULE = "schedule",
}

const DASHBOARD_NAV_BAR = [
  {
    label: "Home",
    key: ROUTE.HOME,
    icon: <HomeIcon />,
  },
  {
    label: "Bikes",
    key: ROUTE.BIKES,
    icon: <MotorcycleIcon style={{ width: "30px" }} />,
  },
  {
    label: "Blocks",
    key: ROUTE.PARTNER_BIKES_BLOCKS,
    icon: <Blocks />,
  },
  {
    label: "Booking",
    key: ROUTE.BOOKING,
    icon: <BookingIcon />,
  },
  {
    label: "Schedule",
    key: ROUTE.SCHEDULE,
    icon: <ScheduleIcon />,
  },
];

const { Header, Sider, Content } = Layout;

// Update the base path here
const BASE_PATH = "/partner/dashboard";

const getPathFromKey = (key: string): string => {
  return `${BASE_PATH}/${key}`;
};

const getKeyFromPath = (pathname: string): string => {
  const key = pathname.replace(`${BASE_PATH}/`, ""); // Remove base path
  return key || ROUTE.HOME; // Default to HOME if path is empty
};

const PartnerLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const location = useLocation();

  const currentKey = getKeyFromPath(location.pathname); // Determine active tab based on the URL

  const handleMenuSelect = ({ key }: { key: string }) => {
    if (key !== currentKey) {
      // Only navigate if the selected tab is different from the current one
      navigate(getPathFromKey(key));
    }
  };

  return (
    <Layout className={style["main-container"]}>
      <Sider
        className={collapsed ? style["collapsed"] : style["not-collapsed"]}
      >
        <Menu
          theme="dark"
          mode="inline"
          items={[...DASHBOARD_NAV_BAR]}
          selectedKeys={[currentKey]} // Highlight the tab based on the URL
          onSelect={handleMenuSelect}
        />
      </Sider>

      <Layout className={style["left-content"]}>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <div className={style["wrap-header"]}>
            <TopHeader
              setCollapsed={setCollapsed}
              collapsed={collapsed}
              isPartner
            />
          </div>
        </Header>
        <Content
          style={{
            minHeight: 280,
            background: "transparent",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default PartnerLayout;
