import { useState } from "react";
import { TableProps } from "antd";
import AntTable from "../components/CommonTable";
import dayjs from "dayjs";
import tableStyle from "../style/tableCol.module.scss";
import { ConvertFirstLetter } from "../utils/ConvertFirstLetter";
import { Data, BookingData } from "../utils/const/bookingConst";
import { ReactComponent as OpenLink } from "../assests/nav/open_link.svg";
import hamburger from "../assests/nav/hamburger.svg";
import { changeTextColor } from "../utils/changeTextColor";
import formatName from "../utils/formatName";
const BookingTable = ({
  data,
  setShowDrawer,
  setSelectedData,
  isDropOff,
  isPickup,
}: {
  data: BookingData[];
  setShowDrawer: any;
  setSelectedData: any;
  isDropOff?: boolean;
  isPickup?: boolean;
}) => {
  const [showEditDeleteAndUpdateMenu, setShowEditDeleteAndUpdateMenu] =
    useState({
      show: false,
      id: "",
    });

  const columns: TableProps<Data>["columns"] = [
    {
      title: "Booking Id",
      dataIndex: "_id",
      key: "_id",
      width: 200,
      render: (_, record) => (
        <div
          className={tableStyle["wrap-img-name"]}
          style={{ justifyContent: "flex-start", cursor: "pointer" }}
          onClick={() => {
            setShowDrawer(true);
            setSelectedData(record);
          }}
        >
          <p className={tableStyle["name"]}>{record._id ? record._id : "--"}</p>
          <OpenLink />
        </div>
      ),
    },
    {
      title: "Bike Name",
      dataIndex: ["bikeId", "name"],
      key: "name",
      width: 150,
      render: (bikeName: string) => <p>{bikeName ? bikeName : "--"}</p>,
    },

    {
      title: "Mobile No",
      dataIndex: ["userId", "mobile"],
      key: "mobile",
      width: 150,
      render: (mobile: string) => <p>{mobile ? mobile : "--"}</p>,
    },
    {
      title: "Rider Name",
      dataIndex: ["userId", "firstName"],
      key: "firstName",
      width: 150,
      render: (firstName: string) => (
        <p>{firstName ? formatName(firstName) : "--"}</p>
      ),
    },
    {
      title: "Booking Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (name: string) => (
        <p style={{ color: changeTextColor(name) }}>
          {ConvertFirstLetter(name)}
        </p>
      ),
    },
    {
      title: "Station Name",
      dataIndex: ["stationId", "name"],
      key: "name",
      width: 200,
      render: (name: string) => <p>{ConvertFirstLetter(name)}</p>,
    },
    {
      title: "Pickup Date",
      dataIndex: "from",
      key: "from",
      width: 200,
      render: (text: any) => <p>{dayjs(text).format("DD MMM YYYY, hA")}</p>,
    },
    {
      title: "Drop Date",
      dataIndex: "to",
      key: "to",
      width: 200,
      render: (text: any) => <p>{dayjs(text).format("DD MMM YYYY, hA")}</p>,
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   width: 70,
    //   render: (_, record) => (
    //     <>
    //       {showEditDeleteAndUpdateMenu.show &&
    //         showEditDeleteAndUpdateMenu.id === record._id && (
    //           <div className={tableStyle["edit-delete-update-wrap"]}>
    //             <div className={tableStyle["blue-text"]}>Cancel Booking</div>
    //           </div>
    //         )}
    //       <div
    //         onClick={() => {
    //           setShowEditDeleteAndUpdateMenu({
    //             show:
    //               !showEditDeleteAndUpdateMenu.show ||
    //               showEditDeleteAndUpdateMenu.id !== record._id,
    //             id: record._id,
    //           });
    //         }}
    //         className={tableStyle["edit-delete-update-img"]}
    //       >
    //         <img
    //           src={hamburger}
    //           alt="is edit"
    //           style={{ width: "10px", height: "20px" }}
    //         />
    //       </div>
    //     </>
    //   ),
    // },
  ];
  if (isDropOff || isPickup) {
    columns.splice(3, 0, {
      title: "Ride Status",
      dataIndex: "rideStatus",
      key: "rideStatus",
      width: 150,
      render: (name: string) => (
        <p style={{ color: changeTextColor(name) }}>
          {ConvertFirstLetter(name)}
        </p>
      ),
    });
  }
  if (isDropOff) {
    columns.splice(5, 1);
  }
  if (isPickup) {
    columns.splice(6, 1);
  }
  return <AntTable columns={columns} data={data} />;
};

export default BookingTable;
