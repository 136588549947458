import { useState } from "react";
import API from "../api";
import { Drawer, message } from "antd";
import dayjs from "dayjs";
import style from "../style/tableCol.module.scss";
import { ConvertFirstLetter } from "../utils/ConvertFirstLetter";
import { changeTextColor } from "../utils/changeTextColor";
import CommonButton from "../components/CommonButton";
import formatName from "../utils/formatName";
import { Checkbox } from "antd";

const CheckboxGroup = Checkbox.Group;

const plainOptions = [
  "Got the documentation done",
  "Collected remaining rent (₹8142.05)",
  "Collected security deposit (₹5000.00)",
  "Provided helmet to the customer",
  " Provided bike documents to the customer",
  "Took video of the bike",
  "Asked customer to take a video of the bike",
  "Clicked photo of the customer with the bike",
];

const BookingDetailsDrawer = ({
  showDrawer,
  setShowDrawer,
  selectedData,
  isSchedulePage,
}: {
  showDrawer: boolean;
  setShowDrawer: any;
  selectedData: any;
  isSchedulePage?: boolean;
}) => {
  const [showOtpDrawer, setShowOtpDrawer] = useState(false);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [enterOtp, setEnterOtp] = useState("");
  const onChange = (list: string[]) => {
    setCheckedList(list);
  };

  const submitOtpForStartRide = async () => {
    const apiCallData = {
      bookingId: selectedData._id,
      otp: enterOtp,
    };

    const getStationData = await API.admin.SubmitOtpForStartRide(apiCallData);
    if (!API.validators.checkSuccessCode(getStationData)) {
      return message.error("Technical error");
    }
    setEnterOtp("");
    setCheckedList([]);
  };

  return (
    <div style={{ position: "relative" }}>
      <Drawer
        title="Details"
        placement={"right"}
        width={500}
        onClose={() => setShowDrawer(false)}
        open={showDrawer}
        closable={false}
        // extra={<div onClick={() => setShowDrawer(false)}>X</div>}
        extra={
          <div
            style={{
              display: "flex",
              gap: "150px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {!isSchedulePage && selectedData?.orderId?.status === "PAID" && (
              <CommonButton
                color="green"
                onClick={() => {
                  setShowOtpDrawer(true);
                }}
              >
                Ride Start
              </CommonButton>
            )}
            <div
              onClick={() => setShowDrawer(false)}
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              X
            </div>
          </div>
        }
      >
        <div
          className={style["container"]}
          style={{ width: "450px", padding: "unset", boxShadow: "none" }}
        >
          <p className={style["drawer-heading"]} style={{ marginTop: "0px" }}>
            Bike Details:
          </p>
          <div className={style["wrap-img-name"]}>
            <p className={style["drawer-title"]}>Name:</p>
            <p className={style["sub-title-value"]}>
              {selectedData?.bikeId?.name}
            </p>
          </div>
          <div className={style["wrap-img-name"]}>
            <p className={style["drawer-title"]}>Registration Number:</p>
            <p className={style["sub-title-value"]}>
              {selectedData?.bikeId?.number}
            </p>
          </div>
          {selectedData?.orderId?.deliveryAddress && (
            <div className={style["wrap-img-name"]}>
              <p className={style["drawer-title"]}>Delivery Address:</p>
              <p className={style["value"]}>
                {selectedData?.orderId?.deliveryAddress}
              </p>
            </div>
          )}
          <div className={style["wrap-img-name"]}>
            <p className={style["drawer-title"]}>Ride Status:</p>
            <p
              className={style["value"]}
              style={{ color: changeTextColor(selectedData?.rideStatus) }}
            >
              {ConvertFirstLetter(selectedData?.rideStatus)}
            </p>
          </div>

          <p className={style["drawer-heading"]}>Payment Details:</p>

          <div className={style["wrap-img-name"]}>
            <p className={style["drawer-title"]}>Settled Amount:</p>
            <p className={style["sub-title-value"]}>
              {selectedData?.orderId?.paidAmt}
            </p>
          </div>
          {selectedData?.orderId?.isPartial && (
            <div className={style["wrap-img-name"]}>
              <p className={style["drawer-title"]}>Payment Link:</p>
              <p className={style["sub-title-value"]}>{selectedData._id}</p>
            </div>
          )}
          <div className={style["wrap-img-name"]}>
            <p className={style["drawer-title"]}>Total Amount:</p>
            <p className={style["sub-title-value"]}>
              {selectedData?.orderId?.totalAmt}
            </p>
          </div>
          <div className={style["wrap-img-name"]}>
            <p className={style["drawer-title"]}>Payment Status:</p>
            <p
              className={style["sub-title-value"]}
              style={{ color: changeTextColor(selectedData?.orderId?.status) }}
            >
              {selectedData?.orderId?.status}
            </p>
          </div>

          {/* <hr className={style["hr-line"]} /> */}
          <p className={style["drawer-heading"]}>Booking Details:</p>
          <div className={style["wrap-img-name"]}>
            <p className={style["drawer-title"]}>Id:</p>
            <p className={style["sub-title-value"]}>{selectedData._id}</p>
          </div>

          <div className={style["wrap-img-name"]}>
            <p className={style["drawer-title"]}>Booking Date:</p>
            <p className={style["sub-title-value"]}>
              {dayjs(selectedData?.createdAt).format("MMM DD, YYYY hh:mm A")}
            </p>
          </div>
          <div className={style["wrap-img-name"]}>
            <p className={style["drawer-title"]}>Pickup Date:</p>
            <p className={style["sub-title-value"]}>
              {dayjs(selectedData?.from).format("DD MMM YYYY, hA")}
            </p>
          </div>

          <div className={style["wrap-img-name"]}>
            <p className={style["drawer-title"]}>Drop Date:</p>
            <p className={style["sub-title-value"]}>
              {" "}
              {dayjs(selectedData?.to).format("DD MMM YYYY, hA")}
            </p>
          </div>
          <div className={style["wrap-img-name"]}>
            <p className={style["drawer-title"]}>Booking From:</p>
            <p className={style["sub-title-value"]}>
              {selectedData?.orderId?.client}
            </p>
          </div>
          <div className={style["wrap-img-name"]}>
            <p className={style["drawer-title"]}>Booking Status:</p>
            <p
              className={style["value"]}
              style={{ color: changeTextColor(selectedData?.type) }}
            >
              {ConvertFirstLetter(selectedData?.status)}
            </p>
          </div>
          <p className={style["drawer-heading"]}>User Details:</p>
          <div className={style["wrap-img-name"]}>
            <p className={style["drawer-title"]}>Name:</p>
            <p className={style["sub-title-value"]}>
              {formatName(selectedData?.userId?.firstName) || "--"}
            </p>
          </div>
          <div className={style["wrap-img-name"]}>
            <p className={style["drawer-title"]}>Email:</p>
            <p className={style["sub-title-value"]}>
              {selectedData?.userId?.email || "--"}
            </p>
          </div>
          <div className={style["wrap-img-name"]}>
            <p className={style["drawer-title"]}>Mobile No:</p>
            <p className={style["sub-title-value"]}>
              {selectedData?.userId?.mobile || "--"}
            </p>
          </div>
          <p className={style["drawer-heading"]}>Hub Details:</p>
          <div className={style["wrap-img-name"]}>
            <p className={style["drawer-title"]}>Hub Name:</p>
            <p className={style["sub-title-value"]}>
              {selectedData?.stationId?.name}
            </p>
          </div>
          <p className={style["drawer-heading"]}>Order Items:</p>

          {selectedData?.orderId?.orderItems.map((item: any) => {
            return (
              <div key={item._id}>
                <p className={style["drawer-title"]}>
                  {ConvertFirstLetter(item.type)}
                </p>
                <div className={style["wrap-img-name"]}>
                  <div className={style["wrap-img-name"]}>
                    <p className={style["sub-title-value"]}>Quantity:</p>
                    <p className={style["drawer-title"]}>{item?.qty}</p>
                  </div>
                  <div className={style["wrap-img-name"]}>
                    <p className={style["sub-title-value"]}>Amount:</p>
                    <p className={style["drawer-title"]}>{item?.totalAmt}</p>
                  </div>
                  <div className={style["wrap-img-name"]}>
                    <p className={style["sub-title-value"]}>
                      Delivery Charges:
                    </p>
                    <p className={style["drawer-title"]}>
                      {item?.deliveryCharges}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Drawer>
      <Drawer
        title="Enter OTP"
        placement="top"
        height={550}
        onClose={() => setShowOtpDrawer(false)}
        open={showOtpDrawer}
        closable={true}
        // rootStyle="otpDrawer"
        rootStyle={{
          width: "500px",
          position: "absolute",
          left: "auto",
        }}
        mask={false}
      >
        <p
          className={style["drawer-heading"]}
          style={{ margin: "0px 0px 24px" }}
        >
          Pickup Process
        </p>

        <CheckboxGroup
          options={plainOptions}
          value={checkedList}
          onChange={onChange}
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "20px",
            gap: "10px",
          }}
        />

        <p
          style={{
            width: "100%",
            padding: "8px",
            fontSize: "18px",
            marginBottom: "16px",
            fontWeight: 500,
          }}
        >
          Please enter the OTP to start the ride:
        </p>
        <input
          type="text"
          maxLength={6}
          style={{
            width: "100%",
            padding: "8px",
            fontSize: "16px",
            marginBottom: "16px",
          }}
          onChange={(e) => setEnterOtp(e.target.value)}
        />
        <CommonButton
          color="primary"
          onClick={submitOtpForStartRide}
          disabledValue={checkedList.length < 1 || enterOtp.length < 4}
        >
          Start Ride
        </CommonButton>
      </Drawer>
    </div>
  );
};
export default BookingDetailsDrawer;
