export const enum HomeDeliveryEnum {
  UpTo10 = "upto10",
  UpTo20 = "upto20",
}
export const homeDeliveryOptions = (data: any) => [
  { id: 1, label: "Up to 10Km", value: data.upto10, name: "upto10" },
  { id: 2, label: "Up to 20Km", value: data.upto20, name: "upto20" },
];

export const enum PaymentTypeEnum {
  FullPayment = "pay full amount",
  PartialPayment = "pay partial amount",
}
export const paymentType = ["Full Payment", "Partial Payment"];
export const onlyFullPaymentType = ["Pay Full Amount"];
export const RiderData = (data: any) => [
  {
    id: 1,
    img: "/assets/icons/distance.svg",
    title: "Distance Limit",
    description: `${data?.kmsLimit} kms`,
  },
  {
    id: 2,
    img: "/assets/icons/booking/hurry.png",
    title: "Late Penalty",
    description: `₹${data?.bikeDetails?.timePenalty}/hr`,
  },
  {
    id: 3,
    img: "/assets/icons/booking/money-back.png",
    title: "Distance Penality",
    description: `₹${data?.bikeDetails?.distancePenalty} /km`,
  },
  {
    id: 4,
    img: "/assets/icons/booking/odometer.png",
    title: "Location Timing",
    description: `${data?.locationTimings}`,
  },
];

export const MachineRunDown = (data: any) => [
  {
    id: 1,
    img: "/assets/icons/engine.svg",
    title: "Displacement",
    description: `${data.displacement} CC`,
  },
  {
    id: 2,
    img: "/assets/icons/booking/speedometer.png",
    title: "Transmission",
    description: `${data?.transmissionType}`,
  },
  {
    id: 3,
    img: "/assets/icons/booking/petrol.png",
    title: "Fuel Capacity",
    description: `${data?.tankCapacity} L`,
  },
  {
    id: 4,
    img: "/assets/icons/booking/odometer.png",
    title: "Mileage",
    description: `${data?.mileage} kms`,
  },
  {
    id: 5,
    img: "/assets/icons/booking/kilogram.png",
    title: "Kerb Weight",
    description: `${data.kerbWeight} kg`,
  },
  {
    id: 6,
    img: "/assets/icons/calender.svg",
    title: "Model Year",
    description: `${data.mfgDate}`,
  },
];

export const orderItems = {
  bikeId: "",
  deliveryCharges: 0,
  flatPrice: 0,
  qty: 0,
  specialPrice: 0,
  taxRate: 0,
  totalAmt: 0,
  type: "",
  unitPrice: 0,
  _id: "",
};

export const accessoriesItems = [
  { id: 1, name: "item3", price: 20 },
  { id: 2, name: "item4", price: 30 },
];

export const PriceInfo = [
  {
    id: 1,
    title: "Bike Price",
    description: "1240",
  },
  {
    id: 2,
    title: "Accessories",
    description: "673",
  },
  {
    id: 3,
    title: "Home Delivery",
    description: "85487",
  },
];

export const initialBikeDetails = {
  bikeId: "",
  brandName: "",
  displacement: 0,
  distancePenalty: "",
  imageUrl: "",
  name: "",
  speedLimit: "",
  timePenalty: "",
  transmissionType: "",
  mfgDate: "",
};

export const initialDelivery = {
  accept: false,
  deliveryCharges: {
    upto10: 0,
    upto20: 0,
  },
};
export const initialPricing = {
  price: 0,
  securityDepositAmount: 0,
  acceptPartialPayment: false,
};

export const parseDate = (date: string | string[] | undefined): Date => {
  if (!date) {
    return new Date(); // or handle this case appropriately
  }

  if (Array.isArray(date)) {
    date = date[0];
  }

  return new Date(date);
};

export interface BookingAccessory {
  qty: number;
  id: string;
}

export interface DeliveryInfo {
  address: string;
  deliveryCharge: any;
}

export interface ProfileData {
  name: string;
  mobile: string;
  email: string;
  _id: string;
}

export interface DataForApiCall {
  userId: string;
  stationId: any;
  bikeId: any;
  accessories: BookingAccessory[];
  to: any;
  from: any;
  isPartial: boolean;
  deliveryInfo: DeliveryInfo | null;
}

export interface DataForRZP {
  profileData: ProfileData;
  orderId: string;
  amount: number;
  rzpId: string;
}
export interface BookingData {
  stationId: {
    name: string;
    _id: string;
  };
  status: string;
  rideStatus: string;
  from: string;
  to: string;
  bikeId: {
    bikeVariant: { imgUrl: string };
    name: string;
    _id: string;
  };
  accessories: { qty: number; id: string }[];
  _id?: string;
  userId: {
    email: string;
    phone: string;
    firstName: string;
  };
}
export interface Data {
  _id: string;
  name: string;
  email: string;
  phone: string;
  bookingDate: string;
  status: string;
  amount: string;
  srouce: string;
}

export const initialBookingValues = {
  name: "",
  email: "",
  phone: "",
  city: "",
  bikeId: "",
  from: "",
  to: "",
  stationId: "",
  paymentOption: PaymentTypeEnum.FullPayment,
  accessories: [] as { qty: number; id: string }[], // Ensure this is an array
  homeDelivery: false,
  homeDeliveryData: "",
  homeDeliveryAddress: "",
};

export interface City {
  _id: string;
  name: string;
}
