import React, { useEffect, useState } from "react";
import style from "../style/twoColForm.module.scss";
import CommonButton from "../components/CommonButton";
import BookingTable from "./bookingTable";
import CreateBooking from "./CreateBooking";
import CreateOrder from "./CreateOrder";
import {
  City,
  initialBookingValues,
  BookingData,
} from "../utils/const/bookingConst";
import { DatePicker, message, Select, Space, Input } from "antd";
import API from "../api";
import dayjs, { Dayjs } from "dayjs";
import { disabledPastDate } from "../utils/disabledPastDate";
import { ConvertFirstLetter } from "../utils/ConvertFirstLetter";
import BookingDetailsDrawer from "./BookingDetailsDrawer";
import { storage } from "../utils/storage";
const { RangePicker } = DatePicker;
const { Option } = Select;

function Booking({ isPartner }: { isPartner?: boolean }) {
  const stationId = storage.getSelectedStation().id;
  const [mode, setMode] = useState(
    "home" as "edit" | "create" | "home" | "order"
  );
  const [bookingData, setBookingData] = useState<BookingData[]>([]);
  const [selectedData, setSelectedData] = useState<BookingData[]>([]);
  const [stationList, setStationList] = useState<City[]>([]);
  const [bookingValues, setBookingValues] = useState(initialBookingValues);
  const [showDrawer, setShowDrawer] = useState(false);
  const [filterValue, setFilterValue] = useState({
    fieldName: "",
    searchByFieldNameVal: "",
    cityId: "",
    stationId: "",
    bookingStatus: "",
    rideStatus: "",
    pickupFrom: "",
    pickupTo: "",
  });

  const getStationList = async () => {
    const getStationData = await API.admin.GetStationList({});
    if (!API.validators.checkSuccessCode(getStationData)) {
      return message.error("Technical error");
    }
    setStationList(getStationData.data?.data);
  };

  const getBookingData = async () => {
    const modifyFilterVal: Record<string, any> = {
      cityId: filterValue.cityId || undefined,
      stationId: filterValue.stationId || stationId,
      bookingStatus: filterValue.bookingStatus || undefined,
      rideStatus: filterValue.rideStatus || undefined,
      pickupFrom: dayjs(filterValue.pickupFrom).startOf("day") || undefined,
      pickupTo: dayjs(filterValue.pickupTo).endOf("day") || undefined,
    };

    // Dynamically set the value for the selected field
    if (filterValue.fieldName && filterValue.searchByFieldNameVal) {
      if (filterValue.fieldName === "Booking ID") {
        modifyFilterVal.bookingId = filterValue.searchByFieldNameVal;
      } else if (filterValue.fieldName === "Name") {
        modifyFilterVal.name = filterValue.searchByFieldNameVal;
      } else if (filterValue.fieldName === "Email") {
        modifyFilterVal.email = filterValue.searchByFieldNameVal;
      } else if (filterValue.fieldName === "Phone") {
        modifyFilterVal.phone = filterValue.searchByFieldNameVal;
      }
    }

    // Fetch booking data based on the modified filter values
    try {
      const response = await API.admin.GetBookingData(modifyFilterVal);
      if (!API.validators.checkSuccessCode(response)) {
        message.error("Technical error while fetching booking data");
        return;
      }

      setBookingData(response.data?.data || []);
    } catch (error) {
      message.error("Error fetching booking data");
    }
  };

  const handleFilterDateChange = (
    dates: [Dayjs | null, Dayjs | null] | null
  ) => {
    if (dates) {
      const [start, end] = dates;

      if (start && end && end.isBefore(start)) {
        message.error("End date cannot be earlier than start date.");
        return;
      }

      setFilterValue({
        ...filterValue,
        pickupFrom: start?.toISOString() || "",
        pickupTo: end?.toISOString() || "",
      });
    } else {
      setFilterValue({
        ...filterValue,
        pickupFrom: "",
        pickupTo: "",
      });
    }
  };

  useEffect(() => {
    getStationList();
  }, []);
  useEffect(() => {
    getBookingData();
  }, [filterValue, stationId]);

  console.log(bookingData);

  return (
    <div>
      {showDrawer && (
        <BookingDetailsDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          selectedData={selectedData}
        />
      )}
      {mode === "home" && (
        <div>
          {!isPartner && (
            <div className={style["warp-search-inp"]}>
              <CommonButton color="primary" onClick={() => setMode("create")}>
                Create Booking
              </CommonButton>
            </div>
          )}
          <Space wrap className={style["filter-container"]}>
            <Select
              placeholder="Select Field"
              allowClear={true}
              onChange={(value) => {
                setFilterValue({ ...filterValue, fieldName: value });
              }}
              value={
                filterValue.fieldName
                  ? `Field: ${filterValue.fieldName}`
                  : undefined
              }
              options={[
                { value: "Booking ID", label: "Booking ID" },
                { value: "Name", label: "Name" },
                { value: "Email", label: "Email" },
                { value: "Phone", label: "Phone" },
              ]}
            />
            <Input
              placeholder="Enter Field Value"
              allowClear={true}
              onChange={(e) => {
                setFilterValue({
                  ...filterValue,
                  searchByFieldNameVal: e.target.value,
                });
              }}
            />
            <Select
              placeholder="Select Station Name"
              allowClear={true}
              onChange={(value) => {
                setFilterValue({ ...filterValue, stationId: value });
              }}
              value={
                filterValue.stationId
                  ? `Station: ${
                      stationList.find(
                        (staion) => staion._id === filterValue.stationId
                      )?.name
                    }`
                  : undefined
              }
            >
              {stationList.map((station) => (
                <Option
                  key={station._id}
                  value={station._id}
                  label={station.name}
                >
                  <div>{station.name}</div>
                </Option>
              ))}
            </Select>
            <Select
              placeholder="Booking Status"
              allowClear={true}
              onChange={(value) => {
                setFilterValue({ ...filterValue, bookingStatus: value });
              }}
              value={
                filterValue.bookingStatus
                  ? `Booking Status: ${ConvertFirstLetter(
                      filterValue.bookingStatus
                    )}`
                  : undefined
              }
              options={[
                { value: "ALL", label: "All" },
                { value: "BOOKED", label: "Booked" },
                { value: "CANCELLED", label: "Cancelled" },
              ]}
            />
            <Select
              placeholder="Ride Status"
              allowClear={true}
              onChange={(value) => {
                setFilterValue({ ...filterValue, rideStatus: value });
              }}
              value={
                filterValue.rideStatus
                  ? `Ride Status: ${ConvertFirstLetter(filterValue.rideStatus)}`
                  : undefined
              }
              options={[
                { value: "ALL", label: "All" },
                { value: "NOT_STARTED", label: "Not Started" },
                { value: "ONGOING", label: "Ongoing" },
                { value: "COMPLETED", label: "Completed" },
              ]}
            />
            <RangePicker
              disabledDate={disabledPastDate}
              getPopupContainer={(trigger: any) => trigger.parentNode}
              onChange={handleFilterDateChange}
              size="small"
              value={
                filterValue.pickupFrom && filterValue.pickupTo
                  ? [dayjs(filterValue.pickupFrom), dayjs(filterValue.pickupTo)] // Parsing ISO strings back to Dayjs objects
                  : null
              }
            />
          </Space>

          <BookingTable
            data={bookingData}
            setShowDrawer={setShowDrawer}
            setSelectedData={setSelectedData}
          />
        </div>
      )}
      {mode === "create" && (
        <CreateBooking
          setMode={setMode}
          setBookingValues={setBookingValues}
          bookingValues={bookingValues}
        />
      )}
      {mode === "order" && (
        <CreateOrder setMode={setMode} bookingValues={bookingValues} />
      )}
    </div>
  );
}

export default Booking;
